<template>
  <div class="fb-modal">
    <!-- select stores -->
    <SelectStores :currentStoreIdForFb="currentStoreId"></SelectStores>
    <!-- overlay -->
    <div
      id="overlay"
      class="overlay"
      @click.stop="toggleSideBar('close')"
    ></div>
    <!-- sidebar -->
    <div id="fb-sidebar" class="fb-sidebar">
      <div class="close-btn" @click="toggleSideBar('close')">X</div>
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/website-use/logo_word.png" alt="" />
      </div>
      <hr />
      <ul class="sidebar-list">
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '我的訂單' }"
          @click="go('我的訂單')"
        >
          <img src="@/assets/icon/edit.png" alt="" />
          我的訂單
        </li>
        <li
          class="mb-2 pointer"
          :class="{ active: currentPage === '我的帳單' }"
          @click="go('我的帳單')"
        >
          <img src="@/assets/icon/exclamation.png" alt="" />
          我的帳單
        </li>
      </ul>
    </div>
    <!-- sidebar toggle btn -->
    <div
      id="show-sidebar-btn"
      class="show-sidebar-btn hide-sidebar-btn d-flex align-items-center"
      @click="toggleSideBar('show')"
    >
      <span class="fw-bolder">{{ currentPage }}</span>
      <img src="@/assets/icon/angle-double-small-right.png" alt="" />
    </div>
    <!-- sidebar area -->
    <div id="sidebar-area" class="sidebar-area"></div>
    <!-- router view -->
    <div id="fb-content" class="fb-content">
      <div style="margin-top: 20px"></div>
      <router-view v-if="reloadRouterView"></router-view>
    </div>
  </div>
</template>

<script>
// components
import SelectStores from '@/components/sidebar/SelectStores.vue'

export default {
  components: { SelectStores },
  data() {
    return {
      fb_serverToken: '',
      currentPage: '',
      reloadRouterView: true,
      // 顯示哪個第二階層
      showWhichSecondClass: '',
    }
  },
  created() {
    this.fb_serverToken = this.$methods.getCookie('fb_serverToken')
    this.checkCurrentPage()
  },
  watch: {
    $route(to, from) {
      console.log(to)
      if (to.name === 'Login') return
      this.checkCurrentPage()
      // 如果更換賣場就更新畫面
      console.log(to.params.storeId, from.params.storeId)
      if (to.params.storeId != from.params.storeId) {
        this.reloadRouterView = false
        this.$nextTick(() => {
          this.reloadRouterView = true
        })
      }
    },
  },
  methods: {
    // sidebar 切換
    toggleSideBar(status) {
      if (status === 'close') {
        $('#fb-sidebar').addClass('close-sidebar')
        $('#show-sidebar-btn').removeClass('hide-sidebar-btn')
        $('#sidebar-area').addClass('close-sidebar-area')
        $('#fb-content').addClass('content-width100')
        $('#overlay').removeClass('overlay')
      } else {
        $('#fb-sidebar').removeClass('close-sidebar')
        $('#show-sidebar-btn').addClass('hide-sidebar-btn')
        $('#sidebar-area').removeClass('close-sidebar-area')
        $('#fb-content').removeClass('content-width100')
        $('#overlay').addClass('overlay')
      }
    },
    // 前往
    go(where) {
      switch (where) {
        case "我的訂單":
          this.$router.push({name: 'Fb_MerchOrder'})
          this.toggleSideBar('close')
          break;
        case "我的帳單":
          this.$router.push({name: 'Fb_Bill'})
          this.toggleSideBar('close')
          break;
      }
    },
    // 檢查目前在哪一頁
    checkCurrentPage() {
      this.currentPage = ''
      switch (this.$route.name) {
        case "Fb_MerchOrder":
          this.currentPage = '我的訂單'
          break;
        case "Fb_Bill":
          this.currentPage = '我的帳單'
          break;
      }
    },
  }
}
</script>